exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-back-office-js": () => import("./../../../src/pages/back-office.js" /* webpackChunkName: "component---src-pages-back-office-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-not-found-js": () => import("./../../../src/pages/page-not-found.js" /* webpackChunkName: "component---src-pages-page-not-found-js" */),
  "component---src-templates-private-policy-js": () => import("./../../../src/templates/private-policy.js" /* webpackChunkName: "component---src-templates-private-policy-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */)
}

