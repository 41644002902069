export const URL = process.env.URL;
export const URL_API = process.env.API_URL;

export const WHALLER_URI = 'https://whaller.civica.eu';

export const TOKEN_WS = URL + '/api/auth/token';
export const NAME_WS = URL_API + '/name';
export const USER_WS = URL_API + '/user';
export const EDUGAIN_URI = URL + '/api/login?idp=';
export const LOGOUT_URL = URL + '/api/saml/logout';


export const languageName = {
    'en-gb': "EN",
    fr: "FR",
    de: "DE",
    hu: "HU",
    it: "IT",
    ro: "RO",
    sw: 'SW',
    es: 'ES',
    pl: 'PL'
};
export const universities = [
    {
        img: '/logo/220/bocconi_220-110.png',
        name: 'Bocconi University',
        EDUGainAuthURL: 'https://idp.unibocconi-prod.it/idp/shibboleth',
        acronym: 'BOCCONI'
    },
    {
        img: '/logo/220/ceu_220-110.png',
        name: 'Central European University',
        EDUGainAuthURL: 'https://excalibur.ceu.edu/simplesaml/saml2/idp/metadata.php',
        acronym: "CEU"
    },
    {
        img: '/logo/220/eui_220-110.png',
        name: 'European University Institute',
        EDUGainAuthURL: 'https://idem.eui.eu/idp/shibboleth',
        acronym: "EUI"
    },
    {
        img: '/logo/220/hertie_220-110.png',
        name: 'Hertie School',
        EDUGainAuthURL: 'https://idp.hertie-school.org/idp/shibboleth',
        acronym: 'HERTIE'
    },
    {
        img: '/logo/220/ie_220-110.png',
        name: 'IE University',
        EDUGainAuthURL: 'https://www.rediris.es/sir/ieuidp',
        acronym: 'IE'
    },
    {
        img: '/logo/220/snspa_220-110.png',
        name: 'National University of Political Studies and Public Administration',
        EDUGainAuthURL: 'https://splp.snspa.ro/smplp/saml2/idp/metadata.php',
        acronym: "SNSPA"
    },
    {
        img: '/logo/220/scpo_220-110.png',
        name: 'Sciences Po',
        EDUGainAuthURL: `${process.env.EDUGAIN_URL_SCPO}`,
        acronym: 'SCPO'
    },
    {
        img: '/logo/220/sse_220-110.png',
        name: 'Stockholm School of Economics',
        EDUGainAuthURL: 'http://fds.idp.hhs.se/adfs/services/trust',
        acronym: "SSE"
    },
    {
        img: '/logo/220/lse_220-110.png',
        name: 'The London School of Economics and Political Science',
        EDUGainAuthURL: 'https://lse.ac.uk/idp',
        acronym: "LSE"
    }, {
        img: '/logo/220/sgh_220-110.png',
        name: 'SGH Warsaw School of Economics',
        EDUGainAuthURL: 'https://cas.sgh.waw.pl/cas/idp/metadata',
        acronym: "SGH"
    },
]

export const courses = [
    {
        university: universities[7],
        name: 'Economic Sociology (SO430)',
        teacher: 'Dr Rebecca Elliott STC 211',
        summary: 'Topics covered in the course include: critical approaches to economy and society; economic rationality; the sociology of economics; morals and markets.'
    },
    {
        university: universities[0],
        name: 'Cities, cultural, tourism and urban life (20518)',
        teacher: 'MOTTIRONI Cristina',
        summary: 'More than half of the world’s population lives in cities where various social, economic, political and cultural functions interact to create unique urban...'
    }
]

export const ROLE = {
    admin: 'ROLE_ADMIN',
    user: 'ROLE_USER',

    //NEW ROLES
    admin_university: "ROLE_ADMIN_UNIVERSITY",
    academics_manager: "ROLE_ACADEMICS_MANAGER",
    courses_approver: 'ROLE_COURSES_APPROVER',
    courses_manager: "ROLE_COURSES_MANAGER",
    news_manager: "ROLE_NEWS_MANAGER",
    researcher:"ROLE_RESEARCHER",
    staff: 'ROLE_STAFF',
    academic: 'ROLE_ACADEMIC',
    bachelor: "ROLE_BACHELOR",
    esr: "ROLE_ESR",
    graduate: "ROLE_GRADUATE",
    student_all_levels: "ROLE_STUDENT_ALL_LEVELS",
    teacher_boccon: "ROLE_TEACHER_BOCCON",
    teacher_ceu: "ROLE_TEACHER_CEU",
    teacher_hertie: "ROLE_TEACHER_HERTIE",
    teacher_ie: "ROLE_TEACHER_IE",
    teacher_lse: "ROLE_TEACHER_LSE",
    teacher_scpo: "ROLE_TEACHER_SCPO",
    teacher_sgh: "ROLE_TEACHER_SGH",
    teacher_snspa: "ROLE_TEACHER_SNSPA",
    teacher_sse: "ROLE_TEACHER_SSE",
    temp: "ROLE_TEMP",
}

export const INPUT_MAX_LENGTH = {
    NEWS_TITLE: 1000,
    NEWS_SHORT_DESCRIPTION: 1000,
    COURSE_TITLE: 500,
    COURSE_SHORT_DESCRIPTION: 500,
    COURSE_LONG_DESCRIPTION: 2000,
    ACADEMIC_FIRST_NAME: 50,
    ACADEMIC_LAST_NAME: 50,
    MAIL_SUBJECT: 300
}

export const MAILS = {
    MAX_FILES_COUNT: 3,
    MAX_CONTENT_SIZE: 2 * 2 ** 20,
    SENT_MAIL_TIMEOUT: 2000
}
