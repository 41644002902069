import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAlerts} from "../../services/api/newsService";


export const fetchAlerts = createAsyncThunk(
    "alerts/updateAsync",
    async(language) => {
       const response = await getAlerts(language);
       return response.data;
    }
)

export const alertSlice = createSlice({
    name: "alerts",
    initialState: {
        value: []
    },
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(fetchAlerts.fulfilled, (state,action) => {
            state.value = action.payload;
        })
    }
});

export default alertSlice.reducer;