import QueryString from "qs";
import {isLoggedIn, logout, setToken} from "../auth";

const axios = require("axios");
export const WSInstance = axios.create({
  paramsSerializer: (params) =>
    QueryString.stringify(params, { arrayFormat: "repeat" }),
});

WSInstance.interceptors.request.use(
  async (config) => {
    config.headers["x-auth-token"] = localStorage.getItem("token");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

WSInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401) {
        try {
          await setToken();
          return WSInstance.request(originalRequest);
        } catch (err) {
          if (isLoggedIn()) {
            logout();
          }
          throw err;
        }
      } else {
        throw error.response;
      }
    }
    Promise.reject(error);
  }
);
