import {configureStore} from '@reduxjs/toolkit';
import listOfValuesSliceReducer from './common/listOfValueSlice'
import alertSliceReducer from './opportunities/alertSlice'


const store = configureStore({
     reducer: {
        listOfValues : listOfValuesSliceReducer,
        alert: alertSliceReducer
     }
})


export default store;